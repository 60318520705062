<template>
	<div
		v-click-outside="setShowListStatus"
		class="container-btn-change-status-task"
		:style="{
			backgroundColor: `#${base16ToHexa(currentStatus.factor)}`,
			color: 'white'
		}"
		style="position: relative"
	>
		<button
			type="button"
			class="btn-change-status-task"
			style="text-transform: uppercase"
			:style="{
				backgroundColor: `#${base16ToHexa(currentStatus.factor)}`,
				border: `1px solid #${base16ToHexa(currentStatus.factor)}`
			}"
			:disabled="disabled"
			@click="showListStatus = !showListStatus"
		>
			{{ currentStatus.text }}
		</button>
		<button
			type="button"
			class="btn-arrow-change-status-task"
			:style="{
				backgroundColor: `#${base16ToHexa(currentStatus.factor)}`,
				border: `1px solid #${base16ToHexa(currentStatus.factor)}`
			}"
			:disabled="disabled"
			@click="nextStatus(currentStatus)"
		>
			<i class="fa fa-caret-right" aria-hidden="true" />
		</button>
		<div v-show="showListStatus" class="list-status-planning">
			<ul>
				<li
					class="status"
					style="color: black"
					v-for="(status, index) in statuses"
					v-if="status.value !== 9"
					:key="index"
					@click="setCurrentStatusText(status)"
				>
					<span
						style="display: inline-block; border-radius: 2px; width: 12px; height: 12px; margin-right: 10px"
						:style="{
							backgroundColor: `#${base16ToHexa(status.factor)}`,
							border: `1px solid #${base16ToHexa(status.factor)}`
						}"
					/>
					{{ status.text }}
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import ClickOutside from 'vue-click-outside';
import GlobalMixin from '@/mixins/global.mixin';

export default {
	name: 'ChangeStatusPlanning',

	directives: {
		ClickOutside
	},

	mixins: [languageMessages, GlobalMixin],

	props: {
		statusId: { type: Number, default: 0 },
		disabled: { type: Boolean, default: false }
	},

	data() {
		return {
			showListStatus: false,
			myStatus: null
		};
	},

	computed: {
		statuses() {
			let statuses = this.FormMenu(1122);
			return statuses.sort((a, b) => a.value - b.value);
		},
		currentStatus: {
			get() {
				const response = this.statuses.filter((st) => {
					if (!this.myStatus) {
						return st.value === this.statusId;
					}
					return st.value === this.myStatus.value;
				});
				return response[0];
			},
			set(newVal) {
				this.myStatus = newVal;
			}
		}
	},

	methods: {
		setShowListStatus() {
			if (this.showListStatus) {
				this.showListStatus = false;
			}
		},
		setCurrentStatusText(status) {
			this.currentStatus = status;
			this.emitEvent(status);
			this.showListStatus = false;
		},
		nextStatus(status) {
			const indexStatus = this.statuses.findIndex((st) => st.value === status.value);
			if (indexStatus + 1 < this.statuses.length) {
				this.emitEvent(this.statuses[indexStatus + 1]);
				this.currentStatus = this.statuses[indexStatus + 1];
			}
		},
		emitEvent(status) {
			this.$emit('status:selected', status);
		}
	}
};
</script>

<style></style>
