import moment from 'moment';

export default {
	name: 'dateMixin',

	methods: {
		/**
		 * Format de duration "0000-00-00T00:00:000Z"
		 * Value returnType "toNumber | toString"
		 * @param duration {String}
		 * @params dayDuration {number}
		 * @params returnType {String}
		 * @returns {number}
		 */
		convertTimeToMinute(duration) {
			const durationSplited = duration.split('T')[1];
			const timeSplited = durationSplited.split(':');
			const hour = parseInt(timeSplited[0]);
			const minute = parseInt(timeSplited[1]);

			return hour * 60 + minute;
		},
		/**
		 * format time "0000-00-00T00:00:00Z"
		 * @param dayDuration {number}
		 * @param time {string}
		 * @returns {string}
		 */
		convertTimeToString(duration, dayDuration = 0) {
			const convertTime = this.convertTimeToMinute(duration);
			const _d = new Date(Date.UTC(0, 0, 0, 0, convertTime, 0));

			if (dayDuration > 0) {
				return `${dayDuration}j ${this.addZero(_d.getUTCHours())}H${this.addZero(_d.getUTCMinutes())}`;
			}

			return `${this.addZero(_d.getUTCHours())} h ${this.addZero(_d.getUTCMinutes())}`;
		},
		/**
		 * Format de start "0000-00-00T00:00:000Z | 0000-00-00"
		 * Format de duration "0000-00-00T00:00:000Z"
		 * @param start {String}
		 * @param dayDuration {Number}
		 * @param duration {String}
		 * @returns {moment.Moment}
		 */
		addDateAndTime(start, dayDuration = 0, duration) {
			const startDate = new Date(start);
			const convertTimeStartDate = this.convertTimeToMinute(start);
			const durationConverted = this.convertTimeToMinute(duration);
			let durationToDate = new Date(Date.UTC(0, 0, 0, 0, durationConverted + convertTimeStartDate, 0));

			if (durationToDate.getDate() === 1) {
				dayDuration += durationToDate.getDate() - 1;
			}
			if (dayDuration === 0) {
				return moment(startDate).add(durationConverted, 'minutes');
			} else {
				const newStartDate = new Date(startDate.setDate(startDate.getDate() + dayDuration));
				return new Date(
					Date.UTC(
						newStartDate.getFullYear(),
						newStartDate.getMonth(),
						newStartDate.getDate(),
						durationToDate.getHours(),
						durationToDate.getMinutes(),
						0
					)
				);
			}
		},
		/**
		 * Format start "0000-00-00T00:00:00Z"
		 * Format end "0000-00-00T00:00:00Z"
		 * @param start {string}
		 * @param end {string}
		 * @returns {string}
		 */
		durationTimeBetweenTwoDate(start, end) {
			const startConverted = this.convertTimeToMinute(start);
			const endConverted = this.convertTimeToMinute(end);
			const minusTime = endConverted - startConverted;
			const dateWithMinusTime = new Date(Date.UTC(0, 0, 0, 0, minusTime, 0));
			return `0001-01-01T${this.addZero(dateWithMinusTime.getUTCHours())}:${this.addZero(dateWithMinusTime.getUTCMinutes())}:00Z`;
		},
		addZero(time) {
			const _time = parseInt(time);
			return _time >= 10 ? _time : '0' + _time;
		}
	}
};
